<template>
	<div>
		<div class="container-fluid">
			<div class="container">
				<div class="row">
					<div class="col">
						<h4 class="text-left title">Overzicht betalingen</h4>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid bg-white">
			<div class="container">
				<div class="row py-4">
					<div class="col">
						<table class="table table-bordered table-hover table-sm">
							<thead>
								<td>Datum</td>
								<td>ID</td>
								<td>Bedrag</td>
								<td>Omschrijving</td>
								<td>Status</td>
								<!-- <td> </td> -->
							</thead>
							<tbody>
								<tr v-for="o in orders" :key="o.id">
									<td>{{ o.createdAt }}</td>
									<td>{{ o.id }}</td>
									<td>{{ o.amount.currency }} {{ o.amount.value }}</td>
									<td>{{ o.description }}</td>
									<td>{{ o.status }}</td>
									<!-- <td class="text-center" @click="deletePayment(index, o.id)"> <i class="fa fa-lg fa-trash"></i> </td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data: () => ({
		URL: '/php/mollie-api-php/examples/',
		orders: [],
	}),

	created() {
		// console.clear();
		this.listPayments();
	},

	methods: {
		listPayments: function () {
			axios
				.get(this.URL + 'payments.php')
				.then((result) => {
					// console.log(result.data);
					this.orders = result.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style>
</style>
